import React from 'react'

const Progressbar = ({ bgcolor, progress, height }) => {

	const Parentdiv = {
		height: height,
		width: '60%',
		backgroundColor: 'whitesmoke',
		borderRadius: 20,
		margin: '0.8em',
	}

	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		backgroundColor: bgcolor,
		borderRadius: 20,
		textAlign: 'right'
	}

	return (
		<div style={Parentdiv}>
			<div style={Childdiv}></div>
		</div>
	)
}

export default Progressbar;