import './App.css';
import { React, useState } from 'react';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import Progressbar from './Component/progressbar'; 

function App() {
    const [selectedExperience, setSelectedExperience] = useState('work');
    const scroll = (anchorName) => {
        if (anchorName) {
            let anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
            }
        }
    }

    const switch_experience_tag = (tabName) => {
        let current_element = document.getElementById(selectedExperience);
        current_element.style.display = 'none';
        setSelectedExperience(tabName);
        let next_element = document.getElementById(tabName);
        next_element.style.display = '';
        next_element.animate([{ opacity: 0 }, { opacity: 1 }], 500);
    }

  return (
      <div className="App">
          <div className="topbar" id="top">
              <div
                  className="topbar-items"
                  onClick={(e) => { scroll('about') }}>
                  About
              </div>
              <div
                  className="topbar-items"
                  onClick={(e) => { scroll('experience') }}>
                  Experience
              </div>
              <div
                  className="topbar-items"
                  onClick={(e) => { scroll('project') }}>
                  Project
              </div>
              <div
                  className="topbar-items"
                  onClick={(e) => { scroll('contact') }}>
                  Contact
              </div>
          </div>
          <div className="back" title="Back to Top">
              <ArrowCircleUpRoundedIcon
                  fontSize="large"
                  onClick={(e) => scroll('top')}>
              </ArrowCircleUpRoundedIcon>
          </div>
          <div className="content">
              <div className="">
                  <span className="intro">Hello, I'm</span>
                  Xiaohan Su
                  <span className="intro">
                      A
                      <span style={{ color: '#61dafb' }} >&nbsp;software developer&nbsp;</span>
                      based in London
                  </span> 
              </div>
          </div>
          <div className="head-line" id="about">&#10148; About.</div>
          <div className="about">
              <div className="skills">
                  <div className="skill-title">Tech Skills: </div>
                  <div className="skill-bar">
                      <p>JavaScript <span>(NodeJS, ReactJS)</span></p>
                      <Progressbar bgcolor="#61dafb" progress='90' height='2vh' />
                      <span> 90%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Typescript <span>(Angular)</span></p>
                      <Progressbar bgcolor="#61dafb" progress='80' height='2vh' />
                      <span> 80%</span>
                  </div>
                  <div className="skill-bar">
                      <p>HTML5/CSS</p>
                      <Progressbar bgcolor="#61dafb" progress='80' height='2vh' />
                      <span> 80%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Python</p>
                      <Progressbar bgcolor="#61dafb" progress='80' height='2vh' />
                      <span> 80%</span>
                  </div>
                  <div className="skill-bar">
                      <p>ASP.NET</p>
                      <Progressbar bgcolor="#61dafb" progress='80' height='2vh' />
                      <span> 80%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Java</p>
                      <Progressbar bgcolor="#61dafb" progress='70' height='2vh' />
                      <span> 70%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Database <span>(MySQL, SQL, MongoDB)</span></p>
                      <Progressbar bgcolor="#61dafb" progress='60' height='2vh' />
                      <span> 60%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Machine Learning</p>
                      <Progressbar bgcolor="#61dafb" progress='60' height='2vh' />
                      <span> 60%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Unity <span>(with C#)</span></p>
                      <Progressbar bgcolor="#61dafb" progress='40' height='2vh' />
                      <span> 40%</span>
                  </div>
              </div>

              <div className="skills">
                  <div className="skill-title">Language Skills: </div>
                  <div className="skill-bar">
                      <p>Mandarin</p>
                      <Progressbar bgcolor="#61dafb" progress='100' height='2vh' />
                      <span> 100%</span>
                  </div>
                  <div className="skill-bar">
                      <p>English</p>
                      <Progressbar bgcolor="#61dafb" progress='80' height='2vh' />
                      <span> 80%</span>
                  </div>
                  <div className="skill-bar">
                      <p>Cantonese</p>
                      <Progressbar bgcolor="#61dafb" progress='60' height='2vh' />
                      <span> 60%</span>
                  </div>
                  <div className="skill-bar">
                      <p>German</p>
                      <Progressbar bgcolor="#61dafb" progress='80' height='2vh' />
                      <span> 20%</span>
                  </div>
              </div>
          </div>
          <div className="head-line" id="experience">&#10148; Experience.</div>
          <div className="experience">
              <div className="experienceSidebar">
                  <div className="sidebarItem"
                      style={{ color: selectedExperience == 'work' ? '#61dafb' : 'white' }}
                      onClick={(e) => { switch_experience_tag('work') }}>
                    Working
                  </div>
                  <div className="sidebarItem"
                      style={{ color: selectedExperience == 'education' ? '#61dafb' : 'white' }}
                      onClick={(e) => { switch_experience_tag('education') }}>
                    Education
                  </div>
                  <div className="sidebarItem"
                      style={{ color: selectedExperience == 'other' ? '#61dafb' : 'white' }}
                      onClick={(e) => { switch_experience_tag('other') }}>
                    Other
                  </div>
              </div>
              <div className="experienceContent">
                  <div id="work" style={{ display: '' }}>
                      <div className="experience-sub-content">
                          <div className="experiencetitle">Software Engineer, <a href="https://invictiq.com/">@InvictIQ</a> <br /><span>2022.11 - Present</span></div>
                          <p>- Developed web applications with React.js, Node.js and MySQL</p>
                          <p>- Designed and developed REST API endpoints and integrated them into the online products</p>
                          <p>- Developed and maintained web applications using Angular as frontend and ASP.NET as the backend using Visual Studio as IDE</p>
                          <p>- Collaborated with product managers and fellow engineers in complex projects, ensuring that the product met the needs of clients</p>
                          <p>- Implemented continuous integration and deployment using Azure DevOps, resulting in a better version-control experience and faster deployment cycles</p>
                      </div>
                      <div className="experience-sub-content">
                          <div className="experiencetitle">Research Officer, <a href="https://invictiq.com/">@InvictIQ</a> & <a href="https://www.essex.ac.uk/">@University of Essex</a>  <br /><span>2022.03 - 08</span></div>
                          <p>- Funding graduate thesis, worked as a chatbot developer with InvictIQ</p>
                          <p>- Implemented a healthcare chatbot with context analysis and intent recognition using Google Dialogflow</p>
                          <p>- Developed an online appointment booking application using HTML/CSS and rendered it by Node.js</p>
                      </div>
                      <div className="experience-sub-content">
                          <div className="experiencetitle">Test Assistant (Intern), <a href="http://www.lenovo.com.cn">@Lenovo Information (Shenzhen) Co., Ltd.</a> <br /><span>2019.05 - 09</span></div>
                          <p>- Generate XML2 files for testing and published more than 60 packages</p>
                          <p>- Cooperated with colleagues in Japan for drivers&#39; maintenance</p>
                      </div>
                      <div className="experience-sub-content">
                          <div className="experiencetitle">Frontend Engineer (Intern), <a>@Guangdong Yunyang Technology Co., Ltd.</a> <br /><span>2019.01 - 04</span></div>
                          <p>- Designed and prototyped the company&#39;s official website</p>
                          <p>- Responsible for designing and developing the internal systems</p>
                        </div>
                  </div>

                  <div id="education" style={{ display: 'none' }}>
                      <div className="education">
                          <div>Master&#39;s degree in Advanced Computer Science - with Distinction</div>
                          <p><a href="https://www.essex.ac.uk/">University of Essex</a> - Colchester, UK <span>2021 - 2022</span></p>
                      </div>
                      <div className="education">
                          <div>Bachelor&#39;s degree in Computer Science and Technology</div>
                          <p><a href="https://www.szu.edu.cn/">Shenzhen University</a> - Shenzhen, China <span>2016 - 2020</span></p>
                      </div>
                      <div className="education">
                          <div>Applied Economies & Digital Data Processing - Summer Program </div>
                          <p><a href="https://itu.edu/">International Technological University (ITU)</a> - CA, US <span>Aug. 2018</span></p>
                      </div>
                  </div>

                  <div id="other" style={{ display: 'none' }}>
                      <div className="experience-sub-content">
                          <div>WeChat Mini Program Contest Award <br /><span>2018.07</span></div>
                          <p>- A mini program for recording bills, showing different statistical charts for income and expenses records</p>
                          <p>- Worked as a product manager and wrote documentation for the program</p>
                          <p>- Analysis the customer requirement, designed and prototyped the program</p>
                      </div>
                      <div className="experience-sub-content">
                          <div>Vice president of Student International Communication Association, Shenzhen University <br /><span>2016 - 2018</span></div>
                          <p>- Hosted student-delegations from Thailand & Australia</p>
                          <p>- Co-hosted Shenzhen International Cultural Festival</p>
                          <p>- Organized International student solstice events</p>
                      </div>
                      <div className="experience-sub-content">
                          <div>Member of Military Orchestra of Shenzhen University <br /><span>2016 - 2018</span></div>
                          <p>- Played the piano and flute</p>
                          <p>- Performed in multiple festivals</p>
                          <p>- Performed in the yearly Starlight Concert from 2016 - 2018</p>
                      </div>
                  </div>
              </div>
          </div>
          <div className="head-line" id="project">&#10148; Project.</div>
          <div className="project">
              I graduated from University of Essex with distinct on 2022.
          </div>
          <div className="head-line" id="contact">&#10148; Contact.</div>
          <div className="contact">
              <p>Email :
                  <span><a href="mailto:Xiaohan.su@hotmail.com">Xiaohan.su@hotmail.com</a></span><br />
              </p>
              <p>I'm open for new oppertunities, so don't hesitate to drop me an email :)</p>
          </div>
          <footer>&#169; Xiaohan Su 2023<br />Made with &#9829; and &#9835;</footer>
      </div>
  );
}

export default App;